<template>
  <div id="charts">
    <div class="sidebar-boxes">
      <div @click="addChart(c.name)" v-for="(c, index) in chartList" :key="index" class="box">
        <div class="img">
          <img :style="c.styles" :src="`${publicPath}assets/chart-types/${c.img}`" alt="" />
        </div>
        <h4>{{ c.name }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapActions("elementsModule", [
      "addChartElement",
      "changeActiveElement",
      "changeActiveChart",
    ]),
    ...mapGetters("elementsModule", ["canvasElements"]),
  },
  data() {
    return {
      chartList: [
        {
          name: "LINE",
          img: "apx-lines.png",
          styles: "",
        },
        {
          name: "SMOOTH LINE",
          img: "apx-spline.png",
          styles: "",
        },
        {
          name: "AREA",
          img: "apx-area.png",
          styles: "",
        },
        {
          name: "STACKED AREA",
          img: "apx-area-stacked.png",
          styles: "",
        },
        {
          name: "COLUMN",
          img: "apx-column-grouped.png",
          styles: "",
        },
        {
          name: "BAR",
          img: "apx-bar-grouped.svg",
          styles: "",
        },
        {
          name: "STACKED COLUMN",
          img: "apx-column-stacked.png",
          styles: "",
        },
        {
          name: "STACKED BAR",
          img: "apx-bar-stacked.svg",
          styles: "",
        },
        {
          name: "100% STACKED COL",
          img: "apx-column-stacked100.png",
          styles: "",
        },
        {
          name: "100% STACKED BAR",
          img: "apx-bar-stacked100.svg",
          styles: "",
        },
        {
          name: "RANGE COLUMN",
          img: "apx-column-range.png",
          styles: "",
        },
        {
          name: "TIMELINE",
          img: "apx-timeline.png",
          styles: "",
        },
        {
          name: "FUNNEL",
          img: "apx-funnel.png",
          styles: "",
        },
        {
          name: "SLOPE",
          img: "apx-slope-chart.png",
          styles: "",
        },
        {
          name: "PIE",
          img: "apx-pie.png",
          styles: "",
        },
        {
          name: "DONUT",
          img: "apx-donut.png",
          styles: "",
        },
        {
          name: "POLARAREA",
          img: "apx-polar-area.svg",
          styles: "transform: scale(1.35)",
        },
        {
          name: "RADIALBAR",
          img: "apx-radialbar.svg",
          styles: "transform: scale(1.65)",
        },
        {
          name: "RADAR",
          img: "apx-radar.svg",
          styles: "transform: scale(1.55)",
        },
        {
          name: "HEATMAP",
          img: "apx-heatmap.svg",
          styles: "transform: translateY(8px) scale(1.25)",
        },
        {
          name: "TREEMAP",
          img: "apx-treemap.png",
          styles: "",
        },
        {
          name: "SCATTER",
          img: "apx-scatter.svg",
          styles: "transform: translate(-2px, -3px) scale(1.85)",
        },
        {
          name: "BUBBLE",
          img: "apx-bubble.svg",
          styles: "transform: translate(-8px, -3px) scale(1.85)",
        },
      ],
    };
  },
  methods: {
    generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = "w" + (i + 1).toString();
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;

        series.push({
          x: x,
          y: y,
        });
        i++;
      }
      return series;
    },
    generateBubbleData(baseval, count, yrange) {
      var i = 0;
      var series = [];
      //const cats = ["Apple", "Mango", "Orange", "Papaya", "Strawberry"];
      while (i < count) {
        var x = "Item" + (i + 1);
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;
        var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

        series.push({
          x,
          y,
          z,
        });
        baseval += 86400000;
        i++;
      }
      return series;
    },
    addChart: function (chartType) {
      let chartSrc = {};

      switch (chartType) {
        case "LINE":
          chartSrc = {
            chart: {
              type: "line",
            },
            stroke: {
              width: 4,
              curve: "straight",
            },
            series: [
              {
                name: "Line",
                data: [
                  {
                    x: "Item 1",
                    y: 31,
                  },
                  {
                    x: "Item 2",
                    y: 40,
                  },
                  {
                    x: "Item 3",
                    y: 28,
                  },
                  {
                    x: "Item 4",
                    y: 51,
                  },
                  {
                    x: "Item 5",
                    y: 42,
                  },
                ],
              },
            ],
          };
          break;
        case "SMOOTH LINE":
          chartSrc = {
            series: [
              {
                name: "Likes",
                data: [
                  {
                    x: "1/11",
                    y: 4,
                  },
                  {
                    x: "2/11",
                    y: 3,
                  },
                  {
                    x: "3/11",
                    y: 10,
                  },
                  {
                    x: "4/11",
                    y: 9,
                  },
                  {
                    x: "5/11",
                    y: 29,
                  },
                  {
                    x: "6/11",
                    y: 19,
                  },
                  {
                    x: "7/11",
                    y: 22,
                  },
                  {
                    x: "8/11",
                    y: 9,
                  },
                  {
                    x: "9/11",
                    y: 12,
                  },
                  {
                    x: "10/11",
                    y: 7,
                  },
                  {
                    x: "11/11",
                    y: 19,
                  },
                  {
                    x: "12/11",
                    y: 5,
                  },
                ],
              },
            ],
            chart: {
              type: "line",
              width: 500,
            },
            stroke: {
              width: 5,
              curve: "smooth",
            },
            dataLabels: {
              enabled: false,
            },
          };
          break;

        case "AREA":
          chartSrc = {
            chart: {
              type: "area",
            },
            stroke: {
              width: 4,
              curve: "straight",
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              opacity: 0.3,
            },
            series: [
              {
                name: "Area",
                data: [
                  {
                    x: "Jan",
                    y: 31,
                  },
                  {
                    x: "Feb",
                    y: 40,
                  },
                  {
                    x: "Mar",
                    y: 28,
                  },
                  {
                    x: "Apr",
                    y: 51,
                  },
                  {
                    x: "May",
                    y: 42,
                  },
                  {
                    x: "Jun",
                    y: 56,
                  },
                  {
                    x: "Jul",
                    y: 33,
                  },
                ],
              },
            ],
          };
          break;

        case "STACKED AREA":
          chartSrc = {
            chart: {
              type: "area",
              stacked: true,
            },
            stroke: {
              width: 4,
              curve: "smooth",
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              opacity: 0.3,
            },
            grid: {
              padding: {
                right: 36,
              },
            },
            series: [
              {
                name: "Area 1",
                data: [
                  {
                    x: "Category 1",
                    y: 31,
                  },
                  {
                    x: "Category 2",
                    y: 40,
                  },
                  {
                    x: "Category 3",
                    y: 28,
                  },
                  {
                    x: "Category 4",
                    y: 51,
                  },
                  {
                    x: "Category 5",
                    y: 42,
                  },
                ],
              },
              {
                name: "Area 2",
                data: [
                  {
                    x: "Category 1",
                    y: 20,
                  },
                  {
                    x: "Category 2",
                    y: 32,
                  },
                  {
                    x: "Category 3",
                    y: 38,
                  },
                  {
                    x: "Category 4",
                    y: 22,
                  },
                  {
                    x: "Category 5",
                    y: 56,
                  },
                ],
              },
            ],
          };

          break;

        case "COLUMN":
          chartSrc = {
            series: [
              {
                name: "Column",
                data: [
                  {
                    x: "Item 1",
                    y: 10,
                  },
                  {
                    x: "Item 2",
                    y: 20,
                  },
                  {
                    x: "Item 3",
                    y: 30,
                  },
                  {
                    x: "Item 4",
                    y: 40,
                  },
                ],
              },
            ],
            dataLabels: {
              enabled: false,
            },
            chart: { type: "bar", height: 250, width: 400 },
          };
          break;

        case "BAR":
          chartSrc = {
            series: [
              {
                name: "Bar",
                data: [
                  {
                    x: "Item 1",
                    y: 10,
                  },
                  {
                    x: "Item 2",
                    y: 20,
                  },
                  {
                    x: "Item 3",
                    y: 30,
                  },
                  {
                    x: "Item 4",
                    y: 40,
                  },
                ],
              },
            ],
            dataLabels: {
              offsetX: -2,
              offsetY: -3,
              background: {
                enabled: false,
              },
              style: {
                colors: ["#fff"],
              },
            },
            chart: { type: "bar", height: 230, width: 400 },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: "top",
                },
              },
            },
          };
          break;

        case "PIE":
          chartSrc = {
            series: [11, 24, 32, 13, 32],
            chart: { type: "pie", height: 250, width: 300 },
            labels: ["A", "B", "C", "D", "E"],
            legend: { position: "right" },
            fill: {
              opacity: 1,
            },
          };
          break;

        case "DONUT":
          chartSrc = {
            series: [11, 24, 32, 13, 32],
            chart: { type: "donut", height: 250, width: 300 },
            labels: ["A", "B", "C", "D", "E"],
            legend: { position: "right" },
            fill: {
              opacity: 1,
            },
            grid: {
              padding: {
                left: 20,
              },
            },
          };
          break;

        case "RADAR":
          chartSrc = {
            dataLabels: {
              enabled: false,
            },
            series: [
              {
                name: "Radar",
                data: [
                  {
                    x: "Item 1",
                    y: 40,
                  },
                  {
                    x: "Item 2",
                    y: 50,
                  },
                  {
                    x: "Item 3",
                    y: 30,
                  },
                  {
                    x: "Item 4",
                    y: 40,
                  },
                  {
                    x: "Item 5",
                    y: 70,
                  },
                ],
              },
            ],
            grid: {
              show: false,
            },
            fill: {
              opacity: 0.2,
            },
            chart: { type: "radar", height: 250, width: 300 },
          };
          break;

        case "RADIALBAR":
          chartSrc = {
            series: [61, 24, 72],
            chart: { type: "radialBar", height: 250, width: 300 },
            labels: ["Item A", "Item B", "Item C"],
            legend: { position: "bottom" },
            grid: {
              padding: {
                left: 30,
              },
            },
          };
          break;
        case "POLARAREA":
          chartSrc = {
            series: [42, 47, 52, 58, 65],
            chart: { type: "polarArea", height: 300, width: 390 },
            labels: ["Item A", "Item B", "Item C", "Item D", "Item E"],
            grid: {
              padding: {
                top: 20,
                left: 20,
              },
            },
            dataLabels: {
              enabled: false,
            },
          };
          break;

        case "RANGE COLUMN":
          chartSrc = {
            series: [
              {
                data: [
                  {
                    x: "Item A",
                    y: [1, 5],
                  },
                  {
                    x: "Item B",
                    y: [4, 6],
                  },
                  {
                    x: "Item C",
                    y: [5, 8],
                  },
                  {
                    x: "Item D",
                    y: [3, 11],
                  },
                ],
              },
            ],
            chart: {
              type: "rangeBar",
              height: 300,
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
          };
          break;

        case "TIMELINE":
          chartSrc = {
            series: [
              {
                data: [
                  {
                    x: "Code",
                    y: [
                      new Date("2019-03-01").getTime(),
                      new Date("2019-03-04").getTime(),
                    ],
                  },
                  {
                    x: "Test",
                    y: [
                      new Date("2019-03-04").getTime(),
                      new Date("2019-03-08").getTime(),
                    ],
                  },
                  {
                    x: "Validation",
                    y: [
                      new Date("2019-03-08").getTime(),
                      new Date("2019-03-12").getTime(),
                    ],
                  },
                  {
                    x: "Deployment",
                    y: [
                      new Date("2019-03-12").getTime(),
                      new Date("2019-03-18").getTime(),
                    ],
                  },
                ],
              },
            ],
            chart: {
              type: "rangeBar",
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              type: "datetime",
            },
          };
          break;

        case "STACKED BAR":
          chartSrc = {
            series: [
              {
                name: "Bar 1",
                data: [
                  {
                    x: "Item 1",
                    y: 10,
                  },
                  {
                    x: "Item 2",
                    y: 20,
                  },
                  {
                    x: "Item 3",
                    y: 30,
                  },
                  {
                    x: "Item 4",
                    y: 40,
                  },
                ],
              },
              {
                name: "Bar 2",
                data: [
                  {
                    x: "Item 1",
                    y: 20,
                  },
                  {
                    x: "Item 2",
                    y: 10,
                  },
                  {
                    x: "Item 3",
                    y: 15,
                  },
                  {
                    x: "Item 4",
                    y: 25,
                  },
                ],
              },
            ],
            dataLabels: {
              offsetY: -3,
              background: {
                enabled: false,
              },
              style: {
                colors: ["#fff"],
              },
            },
            chart: { type: "bar", height: 230, width: 400, stacked: true },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: "center",
                },
              },
            },
          };
          break;

        case "STACKED COLUMN":
          chartSrc = {
            series: [
              {
                name: "Column 1",
                data: [
                  {
                    x: "Item 1",
                    y: 10,
                  },
                  {
                    x: "Item 2",
                    y: 20,
                  },
                  {
                    x: "Item 3",
                    y: 30,
                  },
                  {
                    x: "Item 4",
                    y: 40,
                  },
                ],
              },
              {
                name: "Column 2",
                data: [
                  {
                    x: "Item 1",
                    y: 15,
                  },
                  {
                    x: "Item 2",
                    y: 10,
                  },
                  {
                    x: "Item 3",
                    y: 25,
                  },
                  {
                    x: "Item 4",
                    y: 30,
                  },
                ],
              },
            ],
            dataLabels: {
              enabled: false,
            },
            chart: { type: "bar", height: 250, width: 400, stacked: true },
          };
          break;

        case "100% STACKED BAR":
          chartSrc = {
            series: [
              {
                name: "Bar 1",
                data: [
                  {
                    x: "Item 1",
                    y: 10,
                  },
                  {
                    x: "Item 2",
                    y: 20,
                  },
                  {
                    x: "Item 3",
                    y: 30,
                  },
                  {
                    x: "Item 4",
                    y: 40,
                  },
                ],
              },
              {
                name: "Bar 2",
                data: [
                  {
                    x: "Item 1",
                    y: 20,
                  },
                  {
                    x: "Item 2",
                    y: 10,
                  },
                  {
                    x: "Item 3",
                    y: 15,
                  },
                  {
                    x: "Item 4",
                    y: 25,
                  },
                ],
              },
            ],
            dataLabels: {
              background: {
                enabled: false,
              },
              style: {
                colors: ["#fff"],
              },
            },
            chart: {
              type: "bar",
              height: 230,
              width: 400,
              stacked: true,
              stackType: "100%",
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: "center",
                },
              },
            },
            xaxis: {
              labels: {
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          };
          break;

        case "100% STACKED COL":
          chartSrc = {
            series: [
              {
                name: "Column 1",
                data: [
                  {
                    x: "Item 1",
                    y: 10,
                  },
                  {
                    x: "Item 2",
                    y: 20,
                  },
                  {
                    x: "Item 3",
                    y: 30,
                  },
                  {
                    x: "Item 4",
                    y: 40,
                  },
                ],
              },
              {
                name: "Column 2",
                data: [
                  {
                    x: "Item 1",
                    y: 15,
                  },
                  {
                    x: "Item 2",
                    y: 10,
                  },
                  {
                    x: "Item 3",
                    y: 25,
                  },
                  {
                    x: "Item 4",
                    y: 30,
                  },
                ],
              },
            ],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#fff"],
              },
              background: {
                enabled: false,
              },
            },
            chart: {
              type: "bar",
              height: 250,
              width: 400,
              stacked: true,
              stackType: "100%",
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  position: "center",
                },
              },
            },
            yaxis: [
              {
                tickAmount: 5,
                labels: {
                  formatter: function (val) {
                    return val + "%";
                  },
                },
              },
            ],
          };
          break;
        case "HEATMAP":
          chartSrc = {
            series: [
              {
                name: "Metric1",
                data: this.generateData(9, {
                  min: 0,
                  max: 90,
                }),
              },
              {
                name: "Metric2",
                data: this.generateData(9, {
                  min: 0,
                  max: 90,
                }),
              },
              {
                name: "Metric3",
                data: this.generateData(9, {
                  min: 0,
                  max: 90,
                }),
              },
              {
                name: "Metric4",
                data: this.generateData(9, {
                  min: 0,
                  max: 90,
                }),
              },
            ],
            chart: {
              height: 220,
              type: "heatmap",
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              colors: ["#fff"],
            },
          };
          break;
        case "TREEMAP":
          chartSrc = {
            series: [
              {
                data: [
                  {
                    x: "New Delhi",
                    y: 218,
                  },
                  {
                    x: "Kolkata",
                    y: 149,
                  },
                  {
                    x: "Mumbai",
                    y: 184,
                  },
                  {
                    x: "Ahmedabad",
                    y: 55,
                  },
                  {
                    x: "Bangaluru",
                    y: 84,
                  },
                  {
                    x: "Pune",
                    y: 31,
                  },
                  {
                    x: "Chennai",
                    y: 70,
                  },
                  {
                    x: "Jaipur",
                    y: 30,
                  },
                  {
                    x: "Surat",
                    y: 44,
                  },
                  {
                    x: "Hyderabad",
                    y: 68,
                  },
                  {
                    x: "Lucknow",
                    y: 28,
                  },
                  {
                    x: "Indore",
                    y: 19,
                  },
                  {
                    x: "Kanpur",
                    y: 29,
                  },
                ],
              },
            ],
            legend: {
              show: false,
            },
            dataLabels: {
              style: {
                fontSize: 14,
                fontWeight: 700,
                colors: ["#fff"],
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["#fff"],
            },
            chart: {
              height: 320,
              width: 410,
              type: "treemap",
            },
            grid: {
              padding: {
                top: 0,
                bottom: 20,
                left: 10,
                right: 15,
              },
            },
          };
          break;
        case "FUNNEL":
          chartSrc = {
            series: [
              {
                name: "Funnel",
                data: [
                  {
                    x: "Category 1",
                    y: 1380,
                  },
                  {
                    x: "Category 2",
                    y: 1100,
                  },
                  {
                    x: "Category 3",
                    y: 990,
                  },
                  {
                    x: "Category 4",
                    y: 880,
                  },
                  {
                    x: "Category 5",
                    y: 740,
                  },
                  {
                    x: "Category 6",
                    y: 548,
                  },
                  {
                    x: "Category 7",
                    y: 330,
                  },
                  {
                    x: "Category 8",
                    y: 200,
                  },
                ],
              },
            ],
            chart: {
              type: "bar",
              height: 350,
            },
            colors: [
              "#F44F5E",
              "#E55A89",
              "#D863B1",
              "#CA6CD8",
              "#B57BED",
              "#8D95EB",
              "#62ACEA",
              "#4BC3E6",
            ],
            plotOptions: {
              bar: {
                borderRadius: 0,
                borderRadiusApplication: "around",
                horizontal: true,
                barHeight: "80%",
                isFunnel: true,
                distributed: true,
                dataLabels: {
                  position: "center",
                },
              },
            },
            grid: {
              show: false,
              padding: {
                left: 0,
                right: 0,
              },
            },
            xaxis: {
              labels: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
              },
              dropShadow: {
                enabled: true,
              },
              style: {
                colors: ["#fff"],
              },
              background: {
                enabled: false,
              },
            },
            legend: {
              show: false,
            },
          };
          break;
        case "SLOPE":
          chartSrc = {
            series: [
              {
                name: "series 1",
                data: [
                  {
                    x: 'Category 1',
                    y: 43,
                  },
                  {
                    x: 'Category 2',
                    y: 58,
                  },
                ],
              },
              {
                name: "series 2",
                data: [
                  {
                    x: 'Category 1',
                    y: 55,
                  },
                  {
                    x: 'Category 2',
                    y: 21,
                  },
                ],
              },
              {
                name: "series 3",
                data: [
                  {
                    x: 'Category 1',
                    y: 34,
                  },
                  {
                    x: 'Category 2',
                    y: 45,
                  },
                ],
              },
            ],
            chart: {
              type: "line",
              height: 350,
            },
            plotOptions: {
              line: {
                isSlopeChart: true,
              },
            },
            markers: {
              size: 5
            },
            stroke: {
              width: 4,
              curve: "straight"
            },
            legend: {
              position: "top"
            },
            grid: {
              show: false
            }
          };
          break;

        case "BUBBLE":
          chartSrc = {
            series: [
              {
                name: "Bubble1",
                data: this.generateBubbleData(new Date().getTime(), 6, {
                  min: 10,
                  max: 60,
                }),
              },
            ],
            chart: {
              height: 350,
              type: "bubble",
              animations: {
                dynamicAnimation: {
                  enabled: false,
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              opacity: 0.8,
            },
            theme: {
              palette: "palette4",
            },
            xaxis: {
              type: "category",
            },
          };
          break;
        case "SCATTER":
          chartSrc = {
            series: [
              {
                name: "Scatter 1",
                data: this.generateData(20, {
                  min: 10,
                  max: 60,
                }),
              },
            ],
            chart: {
              height: 350,
              type: "scatter",
              animations: {
                dynamicAnimation: {
                  enabled: false,
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              opacity: 0.8,
            },
            markers: {
              size: 7,
            },
            theme: {
              palette: "palette4",
            },
            xaxis: {
              type: "category",
              tickAmount: 12,
            },
          };
          break;
      }
      this.$store
        .dispatch("elementsModule/addChartElement", chartSrc)
        .then((id) => {
          window.setTimeout(() => {
            document.querySelector(".sidebar").scrollTop = 0;

            this.$store
              .dispatch("elementsModule/changeActiveChart", { id: id })
              .then((response) => {
                this.$store.dispatch("elementsModule/changeActiveElement", {
                  id: null,
                });
              })
              .catch((e) => {
                console.error(e);
              });
          }, 300);
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#charts {
  // display: -ms-grid;
  // display: grid;
  // -ms-grid-columns: 1fr 1fr;
  // grid-template-columns: repeat(3, 1fr);
  // grid-gap: 10px;

  >div {
    // cursor: pointer;
    // display: flex;
    // padding: 15px 4px 10px;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // background: darken($color-blue-grey-dark, 1%);
    // border-radius: 3px;
    // min-height: 80px;

    h5 {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 10px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 99px;
    }

    img {
      display: block;
      filter: hue-rotate(-5deg) saturate(0.95);
      opacity: 0.9;
      height: 40px;
    }

    &:hover {

      // background: lighten($color-blue-grey-dark, 2%);
      img {
        filter: hue-rotate(5deg) saturate(0.9) drop-shadow(1px 1px 4px #444);
        opacity: 1;
      }
    }
  }
}
</style>
